.our-team-block .bg-img {
  width: 100%;
  height: 0;
  padding-top: 120%; /* Tạo ra một khung hình vuông với chiều cao tương đương với chiều rộng */
  position: relative;
  overflow: hidden;
}

.our-team-block .bg-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Đảm bảo hình ảnh nằm trong khung kích thước mà không bị méo hoặc căng ra */
}
